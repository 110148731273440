.main-home{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10% 10%;
    padding-top: 225px;
}


@media only screen and (max-width: 1025px) {
    .main-home{
        padding-top: 110px;
        background-position: center center;
    }
}

.main p{
    line-height: 1.8;
    font-size: 15px;
    margin-bottom: 1rem;
    margin-top: 0;
}

section.whyus{

}

section.whyus .grid{
    text-align: center;
}

section.whyus .grid span{
    align-items: center;
    background-color: #2f55d41a;
    color: #fff;
    display: flex;
    font-weight: 500;
    justify-content: center;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    margin: auto;
}
section.whyus .grid span svg{
    color: #2f55d4;
    fill: #2f55d433;
}
section.whyus .grid h5{
    font-size: 18px;
    margin-top: 1.5rem;
}
section.whyus .grid p{
    margin-bottom: 0;
}

section#features{
}

section#features .features-list h5{
    font-size: 1.5rem;
}


.whychooseusx{
    background: url('https://images.unsplash.com/photo-1528731708534-816fe59f90cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bWluaW1hbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8fDA%3D&w=1000&q=80');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.badge-rounded {
    align-items: center;
    background-color: #2f55d41a;
    display: flex;
    font-weight: 500;
    justify-content: center;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
}

.badge-rounded svg {
    color: #2f55d4;
    fill: #2f55d433;
}

section#clients .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    padding: 9px;
    width: 32px;
    height: 32px;
    background: rgba(47, 85, 212, .2);
    color: #2f55d4;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 10px;
}
section#clients .owl-nav [class*='owl-']:hover {
    background: #2f55d4;
    color: #fff;
}

section#clients .owl-carousel .owl-stage{display: flex;}

section#clients .owl-carousel .owl-item .item{
    display: flex;
    flex: 1 0 auto;
    height: 100%;
}
section#clients .owl-carousel .owl-item .item > div{
    box-shadow: 0 8px 35px #4950570d;
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 1.5rem;
    color: #212529;
    background: #FFF;
}
section#clients .owl-carousel .owl-item .item p{
    color: #212529;
}
section#clients .owl-carousel .owl-item .item .user-details div:nth-child(1){
    font-weight: 500;
}
section#clients .owl-carousel .owl-item .item .user-details div:nth-child(2){
    color: #555;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #ffffff !important;
    border: 2px solid #2f55d4;
}

section#clients .client-images img {
    max-height: 94px;
    width: auto !important;
    margin: 10px auto;
    opacity: .6;
    transition: all .25s;
}

section#clients .client-images img:hover {
    opacity: 1;
}

section#plans{

}

section#plans .pills{

}

section#plans .pills button{
    border-radius: 0.4rem;
    color: #a0a9b1;
    padding: 0.6rem 1.4rem;
    font-weight: 500;
    font-size: 14px;
}
section#plans .pills button.active{
    background-color: #2f55d4;
    color: #FFF;
}


section#plans div.plan{
    box-shadow: 0 8px 35px #4950570d;
    border-radius: 7px;
    text-align: center;
    flex: 1 1 auto;
    padding: 2.5rem 1.5rem;
    background-color: #FFF;
    max-width: 415px;
    margin: 0 auto;
}

section#plans div.plan p{
    font-size: 14px;
}
section#plans div.plan div.price{
    color: #343a40;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
section#plans div.plan div.price span:nth-child(1){
    font-size: calc(1.375rem + 1.5vw);
}
section#plans div.plan div.price span:nth-child(2){
    font-size: 16px;
}

section#plans div.plan a.btn-main{
    margin-bottom: 2rem;
}
section#plans div.plan ul{
    text-align: left;
}
section#plans div.plan ul li{
    color: #444;
    font-weight: 400;
}

section#plans div.plan ul li svg{
    color: rgb(34 197 94);
    margin-top: 3px;
}


section#newsletter input{
    height: 48.65px;
    border-radius: 7px;
    font-size: 15px;
    box-shadow: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #FFF;
    border: 1px solid #dee2e6;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
section#newsletter input:focus {
    border-color: #2f55d4;
}




section#terms ul li {
    margin-bottom: 15px;
}

section#terms h2 {
    margin-top: 40px;
    margin-bottom: 15px;
    font-size: 1.75rem;
    font-weight: bold;
}
section#terms p {
    margin-bottom: 1rem;
}
section#terms ol {
    margin-top: 2.5rem;
}