@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap');

.nav-title{
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
    color: white;
}

#search-navbar{
    background: #1F1F1F;
    border: 1px solid #2C2C2C;
    color: #CCC;
    outline: none;
    border-radius: 30px;
}

#search-navbar::placeholder{
    color: rgb(115,115,115)
}

.authnav-button{
    /* padding: 0.75rem 2rem 0.75rem 2rem; */
    border-radius: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.46px;
    background: transparent;
    text-transform: uppercase;
    color: #111;
    border:1px solid #111;
}

nav{
  background-color: transparent;
  transition: all .25s ease-in-out;
}

nav.scrolled {
  margin-top: 0;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 10px 33px -14px #0000001a;
  z-index: 999;
}

nav .nav-links a{
  line-height: 26px;
  color: #343a40cc;
  transition: all .4s;
  background-color: transparent !important;
  padding: 6px 10px;
}

nav .nav-links a:hover{
  color: #2f55d4;
}

.sm-toggler{
    display: none;
}

nav .navbar-btn {
  margin-left: 10px;
  color: #34d1bf;
  background: rgba(52, 209, 191, .2);
  border-color: transparent;
  box-shadow: none;
  padding: 1rem 1.6rem;
}

nav .navbar-btn:hover {
  color: #fff;
  box-shadow: 0 16px 31px -16px #34d1bf;
  background: #2cc4b3;
  border-color: #2cc4b3;
  transform: translateY(-4px);
  outline: none;
  text-decoration: none;
}

@media only screen and (max-width: 1025px) {

    nav {
      margin-top: 0;
      padding: 15px 5px !important;
      background-color: #f8fafe !important;
      box-shadow: 0 10px 33px -14px #0000001a;
      z-index: 999;
    }

    nav > .container{
      position: relative;
    }

    nav .btn-main,nav .btn-main-alt{
      margin-top: 20px;
    }

    nav .nav-links{
      display: none;
    }
    
    nav #navbar-cta{
        width: 75%;
        opacity: 1;
    }

    nav #navbar-cta{
        display: flex;
        position: fixed;
        z-index: 99;
        background-color: #17171B;
        top: 0;
        margin-left: calc(25% - 1rem);
        opacity: 1;
        width: 75%;
        height: 100vh;
        border-left: 1px solid #99999950;
        padding: 30px 25px;
    }

    nav.toggled #navbar-cta{
      --tw-shadow: 0 0px 30vw 30vw rgba(0,0,0,0.7);
      --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .toggler{
      position: absolute;
      right: 15px;
      top: 0px;
      z-index: 999;
      background: white;
      /* height: 3rem;
      width: 3rem;
      border-radius: 50%; */
    }

    .sm-toggler{
        overflow-y: scroll;
        display: flex;
        width: 100%;
        padding-bottom: 100px;
    }

    .nav-buttons{
        display: none;
    }

    nav.toggled .toggler svg path:nth-child(1){
      transform-origin: 5px 7px !important;
    }

    nav.toggled .toggler svg path:nth-child(3){
      transform-origin: 3px 12px !important;
    }
}